import React from "react";
import { useInView } from "react-intersection-observer";

const WebsitesSection = ({ item, index }) => {
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.1 });

  return (
    <section
      className={`bg-gray-100 overflow-hidden md:snap ${
        index % 2 === 0 ? "bg-gray-100" : "bg-gray-900 text-white"
      } min-h-[80vh] flex flex-col items-center justify-center gap-4 px-4 py-24`}
    >
      <div
        className={`relative size-full flex ${
          index % 2 === 0 ? "flex-wrap" : "flex-wrap-reverse"
        } gap-8 items-center justify-center z-0`}
      >
        {index % 2 === 0 && (
          <figure
            ref={ref}
            className="relative h-full lg:max-h-[40%] w-full lg:max-w-[40%] overflow-hidden"
          >
            <img
              src={item.image}
              height={"100%"}
              width={"100%"}
              alt={item.title}
              className="size-full"
            />
            {item.attr && (
              <figcaption className="w-full text-center">
                Copyright ©{" "}
                <a
                  href={item.attr}
                  title={item.title}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={`${
                    index % 2 === 0 ? "text-synapsify-red" : "text-blue-500"
                  }`}
                >
                  {item.attr}
                </a>
              </figcaption>
            )}
          </figure>
        )}
        <div className="size-full max-w-[700px] flex flex-col p-4 gap-4">
          <div className="size-fit overflow-hidden">
            <h1
              className={`text-4xl mb-2 font-bold duration-700 text-center lg:text-left ${
                inView ? "" : "translate-y-[150%]"
              }`}
            >
              {item.title}
            </h1>
          </div>
          <div className="size-fit overflow-hidden">
            <div
              className={`text-xl md:text-justify duration-700 flex flex-col gap-4 ${
                inView ? "" : "translate-y-[150%]"
              }`}
            >
              {item.text}
              {item.link && (
                <a
                  href={item.link}
                  title={item.title}
                  rel="noopener noreferrer"
                  target="_blank"
                  className="text-sm px-6 py-2 bg-synapsify-red text-white w-fit rounded-lg duration-300 hover:bg-gray-400 hover:text-black"
                >
                  Visit
                </a>
              )}
            </div>
          </div>
        </div>
        {index % 2 !== 0 && (
          <figure
            ref={ref}
            className="relative h-full lg:max-h-[40%] w-full lg:max-w-[40%] overflow-hidden"
          >
            <img
              src={item.image}
              height={"100%"}
              width={"100%"}
              alt={item.title}
              className="size-full"
            />
            {item.attr && (
              <figcaption className="w-full text-center">
                Copyright ©{" "}
                <a
                  href={item.attr}
                  title={item.title}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={`${
                    index % 2 === 0 ? "text-synapsify-red" : "text-blue-500"
                  }`}
                >
                  {item.attr}
                </a>
              </figcaption>
            )}
          </figure>
        )}
      </div>
    </section>
  );
};

export default WebsitesSection;
