import React from "react";

const Footer_section = () => {
  return (
    <>
      <div className="bg-black sm:h-[25vh] flex items-center justify-start flex-col">
        <div className="flex flex-wrap mt-6 justify-evenly w-full max-w-4px ">
          <img
            fetchPriority="high"
            className="sm:w-60 w-20 sm:h-40 h-20"
            alt=""
            src="/altroverse.svg"
          />
          <img
            fetchPriority="high"
            className="sm:w-60 w-20 sm:h-40 h-20"
            alt=""
            src="/hive.svg"
          />{" "}
          <img
            fetchPriority="high"
            className="sm:w-60 w-20 sm:h-40 h-20"
            alt=""
            src="/cords.svg"
          />{" "}
          <img
            fetchPriority="high"
            className="sm:w-60 w-20 sm:h-40 h-20"
            alt=""
            src="/mulberry.svg"
          />{" "}
          <img
            fetchPriority="high"
            className="sm:w-60 w-20 sm:h-40 h-20"
            alt=""
            src="/logfire.svg"
          />
        </div>
        <div className="flex sm:mt-6">
          <h1 className="text-white">
            © 2024 Altrova LLC. All rights reserved.
          </h1>
        </div>
      </div>
    </>
  );
};

export default Footer_section;
