import React from "react";
import HeroSection from "./Hero_section";
import section_info from "../utils/section_info";
import WebsitesSection from "./Website_section";
import Footer_section from "./Footer_section";

const Home = () => {
  const sections = section_info();

  return (
    <div>
      <HeroSection />
      {sections.map((item, index) => (
        <WebsitesSection key={index} item={item} index={index} />
      ))}
      <Footer_section />
    </div>
  );
};

export default Home;
