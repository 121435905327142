import React from "react";

const section_info = () => {
  return [
    {
      title: "Altroverse",
      text: (
        <p>
          A synthetic visual data-as-a-service platform that generates precise
          and rich labeled ground truth data for computer vision AI. Use our
          interactive visualizer to customize your scenario with parameters
          including weather, lighting, sensors, agent density and more. Our
          scalable cloud platform enables you to accelerate time to build and
          validate your AI models without needing to worry about real-world data
          collection challenges including privacy, non-compliance, bias, cost,
          statistical rarities, and hazardous scenarios. Inviting players
          integrating Computer Vision in verticals including Autonomous Driving
          / ADAS, Intelligent Transportation Systems, Smart Cities, Robotics,
          Agri-tech, Industrial QA and more to try out Altroverse and get in
          touch for customized solutions at{" "}
          <span className="italic text-synapsify-red">hello@altroverse.ai</span>
          .
        </p>
      ),
      image: "/Altroverse.png",
      link: "https://altroverse.ai",
      // attr: "https://altroverse.ai",
    },
    {
      title: "NeuralCords",
      text: (
        <p>
          A bleeding edge cloud platform for AI-driven emotive text to speech
          and expressive 3D facial animation generation poised to disrupt the
          way content is created.
        </p>
      ),
      image: "/NeuralCords.png",
      link: "https://neuralcords.ai",
      // attr: "https://neuralcords.ai",
    },
    {
      title: "NeuralHive",
      text: (
        <p>
          A serverless, click, play and pay-as-you-go machine learning platform,
          poised to democratize AI/ML. Abstraction is an understatement – Hive
          entirely masks away the complexities of cloud infrastructure
          management, taking care of allocating, scaling and managing the
          required computing resources, allowing for greater agility,
          cost-efficiency and scalability!
        </p>
      ),
      image: "/NeuralHive.png",
      link: "https://neuralhive.ai",
      // attr: "https://neuralhive.ai",
    },
    {
      title: "MulberryDB",
      text: (
        <p>
          A fully serverless and scalable vector database solution designed for
          high-performance AI and machine learning workloads, offering seamless
          pay-per-use pricing.
        </p>
      ),
      image: "/MulberryDB.png",
      link: "https://mulberrydb.ai",
      // attr: "https://mulberrydb.ai",
    },
    {
      title: "LogFire",
      text: (
        <p>
          LogfireAI is a cloud-native log monitoring system that centralizes
          logs from Kubernetes clusters, applications, and environments into an
          intuitive interface. It offers real-time log streaming with powerful
          search, filter, and query options, while being lightweight enough for
          terminal access via a simple logfire tail command. Designed for teams,
          LogfireAI enhances collaboration by allowing shared visibility for
          incident tracking, code deployment, and user behavior analysis.
        </p>
      ),
      image: "/LogFire.png",
      link: "https://logfire.ai/",
      // attr: "https://logfire.ai/",
    },
  ];
};

export default section_info;
